// <div data-controller="components--scroller">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [ "button" ];
  static classes = [ "" ]
  static values = {};

  initialize() {
    this.showButton = false;
  }

  onScroll(e) {
    if (window.scrollY > 500) {
      if (this.showButton === false) {
        this.buttonTarget.style.bottom = "100px";
      }
      this.showButton = true;
    } else {
      if (this.showButton === true) {
        this.buttonTarget.style.bottom = "-100px";
      }
      this.showButton = false;
    }
  }

  scrollToPageTop(e) {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  connect() {

  }

  disconnect() {

  }
}