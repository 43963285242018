import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";

export default class extends Controller {
  static targets = [ "container" ];
  static values = {
    id: Number,
  }

  dismiss(e) {
    api.post("/api/popup/dismiss", { id: this.idValue }).then((res) => {
      if (res) {
        this.containerTarget.style.display = "none";
      }
    })
  }

  connect() {

  }
}