// <div data-controller="fonts--slider">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import Splide from '@splidejs/splide';

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [ "slider", "track" ];
  static classes = [ "" ]
  static values = {
    height: Number
  };

  initialize() {
    this.slider = null;
  }

  connect() {
    this.slider = new Splide(this.sliderTarget, {
      perPage: 5,
      gap: "1rem",
      rewind : true,
      pagination: false,
      padding: 0,
      fixedHeight: this.heightValue,
      // fixedWidth: 300,
      // arrowPath: "", 화살표 svg https://splidejs.com/guides/options/#arrowpath
      breakpoints: {
        // 480: {
        //   perPage: 1,
        //   start: 0
        // },
        660: {
          perPage: 1,
        },
        768: { // md 에 맞춰서 여백 바뀌는 구간
          padding: "2rem",
          // fixedWidth: 300,
          type: "loop",
        },
        990: {
          perPage: 2,
        },
        1320: {
          perPage: 3,
        },
        1650: {
          perPage: 4,
        },
      }
    } );
    // this.trackTarget.classList.remove("hidden");
    this.slider.mount();
  }

  disconnect() {
    this.slider.destroy();
  }
}