// <div data-controller="components--clipboard">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import ClipboardJS from 'clipboard';

export default class extends Controller {
  static targets = [ "copyText"];

  connect() {
    this.clipboard = new ClipboardJS(this.copyTextTarget);
  }

  disconnect() {
    this.clipboard.destroy()
  }
}