// <div data-controller="top_banner">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";

export default class extends Controller {
  static targets = [ "container" ];

  dismiss(e) {
    api.post("/api/top_banners/dismiss").then((res) => {
      if (res) {
        this.containerTarget.style.display = "none";
      }
    })
  }

  connect() {

  }
}