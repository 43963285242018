// <div data-controller="components--slider">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [
      "slider",
      "leftTrack",
      "rightTrack"
  ];
  static classes = [ "" ]
  static values = {};

  initialize() {

  }

  get leftTrackWidth() {
    const current = this.sliderTarget.value - this.sliderTarget.min;
    const total = this.sliderTarget.max - this.sliderTarget.min;
    return current / total;
  }

  get rightTrackWidth() {
    return 1 - this.leftTrackWidth;
  }

  onSliderChange() {
    this.leftTrackTarget.style.width = `${this.leftTrackWidth * 100}%`;
    this.rightTrackTarget.style.width = `${this.rightTrackWidth * 100}%`;
  }

  connect() {
    this.onSliderChange();
  }

  disconnect() {

  }
}