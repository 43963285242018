// <div data-controller="fonts--like">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [ "toggler" ];
  static classes = [ "default", "active" ]
  static values = {
    id: String,
    userSignedIn: Boolean
  };

  initialize() {

  }

  get flashesController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("flashesContainer"), "components--flashes")
  }

  toggleFont = (e) => {
    if (this.userSignedInValue) {
      api.post(`/api/fonts/${this.idValue}/toggle`)
          .then((res) => {
            if (res.data) {
              const { checked, fontName  } = res.data;
              const linkToUserFonts = `<a href='/user_fonts' class='font-bold hover:underline'>즐겨찾기</a>`
              let flashParams = { stack: false, ttl: 7 };
              if (checked) {
                flashParams.message = `${fontName} 폰트가 ${linkToUserFonts}에 추가 되었습니다.`;
              } else {
                flashParams.message = `${fontName} 폰트가 ${linkToUserFonts}에서 제거 되었습니다.`;
              }
              this.flashesController.createFlash(flashParams);
              this.toggleStyle(checked)
            }
          })
    } else {
      let flashParams = { ttl: 15, stack: false };
      flashParams.message = `즐겨찾기 기능은 로그인이 필요해요.<br><a href='/users/sign_in' class='block mt-1 font-bold hover:underline'>바로 로그인하기 &rarr;</a>`;
      this.flashesController.createFlash(flashParams);
    }
  }

  toggleStyle(checked) {
    if (checked) {
      this.togglerTarget.classList.remove(...this.defaultClasses);
      this.togglerTarget.classList.add(...this.activeClasses);
    } else {
      this.togglerTarget.classList.remove(...this.activeClasses);
      this.togglerTarget.classList.add(...this.defaultClasses);
    }
  }

  connect() {

  }

  disconnect() {

  }
}