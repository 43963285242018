// <div data-controller="components--block-killer">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";

export default class extends Controller {
  static targets = [ "noonProtect", "title", "description", "switch" ];

  turnOffBlockMethod(e) {
    localStorage.setItem('adBlockMethodology', 'turnedOff');
    location.reload();
  }

  async connect() {
    // 브라우저에 LocalStorage 자체가 없으면 그냥 차단 시도 안함.
    if (typeof Storage !== "undefined") {
      const adBlockMethodology = localStorage.getItem('adBlockMethodology');

      if (adBlockMethodology === "turnedOff") {
        // White Listed
      } else {
        let adBlockEnabled = false;

        const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
        try {
          await api.get(googleAdUrl)
            .catch(_ => adBlockEnabled = true)
        } catch (e) {
          adBlockEnabled = true
        } finally {
          if (adBlockEnabled) {
            this.titleTarget.innerHTML = `앗! 광고차단 플러그인을<br>사용하고 계신가요?`;
            this.descriptionTarget.innerHTML = `눈누의 운영비는 배너 광고와 후원사 광고에서<br>
      나옵니다. 광고가 정상적으로 노출될 수 있도록<br>
      <span style="font-weight: 700;">광고차단 플러그인을 해제해주세요.</span><br>
      광고차단 플러그인이 아니더라도 백신, 보안 플러그인으로 인해<br>
      해제되지 않는 경우도 있으니 다른 플러그인도 확인해주세요.`
            this.switchTarget.innerHTML = `플러그인을 사용하고 있지 않습니다`;
            this.noonProtectTarget.style.display = "flex";
          }
        }
      }
    }
  }
}