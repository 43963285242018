export const LoadingSpinner = "<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"animate-spin h-5 w-5 text-gray-600 dark:text-gray-400 stroke-current\" fill=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"/><line x1=\"12\" y1=\"6\" x2=\"12\" y2=\"3\" /><line x1=\"6\" y1=\"12\" x2=\"3\" y2=\"12\" /><line x1=\"7.75\" y1=\"7.75\" x2=\"5.6\" y2=\"5.6\" /></svg>";
export const SearchIcon = "<svg class=\"h-5 w-5 text-gray-600 dark:text-gray-400\" fill=\"currentColor\" viewBox=\"0 0 20 20\"><path fill-rule=\"evenodd\" d=\"M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z\" clip-rule=\"evenodd\"/></svg>";

export function getCheckBoxIds(checkBoxTargets) {
  let ids = [];
  checkBoxTargets.forEach((checkBoxTarget) => {
    if (checkBoxTarget.checked) {
      ids.push(checkBoxTarget.value);
    }
  })
  return ids;
}

export function getCheckBoxLabel(checkBoxTargets, defaultString) {
  let nameString;
  let names = [];
  checkBoxTargets.forEach((checkBoxTarget) => {
    if (checkBoxTarget.checked) {
      names.push(checkBoxTarget.dataset.name);
    }
  })

  if (names.length === 0) {
    return defaultString
  }

  nameString = names.shift();
  if (names.length > 0) {
    if (document.documentElement.lang === "en") {
      nameString += ` and ${names.length} more`;
    } else if (document.documentElement.lang === "ja") {
      nameString += ` 他に ${names.length} 件`;
    } else {
      nameString += ` 외 ${names.length}개`;
    }
  }
  return nameString;
}

export default {};